/**
  Conceptually it would be nice to use this file, but it's not currently taking affect.
  TODO: figure it out
**/


$event-bg: #2C6F84;
$current-time-color: #2C6F84;
$today-highlight-bg: #fff;

// $out-of-range-color: lighten(#333, 40%) !default;
// $out-of-range-bg-color: lighten(#333, 70%) !default;

// $calendar-border: #ddd !default;
// $cell-border: #ddd !default;

// $border-color: #ccc !default;

// // Each calendar segment is 1/7th.
// $segment-width: 0.14286% !default;

// $time-selection-color: white !default;
// $time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
// $date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;

// $event-bg: #3174ad !default;
// $event-border: darken(#3174ad, 10%) !default;
// $event-outline: #3b99fc !default;
// $event-color: #fff !default;
// $event-border-radius: 5px !default;
// $event-padding: 2px 5px !default;
// $event-zindex: 4 !default;

// $btn-color: #373a3c !default;
// $btn-bg: #fff !default;
// $btn-border: #ccc !default;

// $current-time-color: #74ad31 !default;

// $rbc-css-prefix: rbc-i !default;

// $today-highlight-bg: #eaf6ff !default;

@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';