$colorPaletteGrayscale200: #BDBDBD;
$colorPaletteGrayscale400: #7E7E7E;
$colorPaletteGrayscale500: #757575;
$colorPaletteGrayscale600: #454545;
$colorPaletteGrayscale700: #2B2B2B;

$colorPaletteBlue100: #d5e2e6;;
$colorPaletteBlue500: #2C6F84;
$colorPaletteRed500: #FF5200;

.componentContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 32px;

  &__minimized {
    @extend .componentContainer;
    width: 90vw;
    gap: 20px;

    .accessTypeContainer {
      padding: 16px;
    }

    .accessTypeTitle {
      font-size: 18px;
    }

    .accessTypeSubtitle {
      font-size: 14px;
    }

    .inputFieldsContainer {
      flex-direction: column;
      gap: 8px;
      padding-right: 16px;
    }

    .inputDropdown {
      max-width: 70vw;
    }

    .inputFieldFlexContainer.linebreak {
      flex-basis: auto;
    }
  }
}

.accessTypeContainer {
  border-width: 1px;
  border-style: solid;
  border-color: $colorPaletteGrayscale200;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;

  &:hover {
    cursor: pointer;
    background-color: $colorPaletteBlue100;
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 4px;
}

.inputCheckbox {
  height: 16px;
  width: 16px;
  accent-color: $colorPaletteBlue500;
}

.accessTypeContentsContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%
}

.accessTypeTitle {
  color: $colorPaletteGrayscale700;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: 400;
}

.accessTypeSubtitle {
  color: $colorPaletteGrayscale500;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 400;
}

.inputFieldsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding-right: 64px;
}

.inputFieldFlexContainer {
  display: flex;
  flex-direction: row;

  &__stretch {
    @extend .inputFieldFlexContainer;
    flex-grow: 1;
  }

  &.linebreak {
    flex-basis: 100%;
  }
}

.inputFieldContainer {
  display: flex;
  flex-direction: column;

  &__stretch {
    @extend .inputFieldContainer;
    flex-grow: 1;
  }
}

.inputFieldTitle {
  color: $colorPaletteGrayscale600;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 400;
  margin: 8px 0px;
}

.inputFieldErrorText {
  align-items: center;
  color: $colorPaletteRed500;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 4px;
  padding-left: 16px;
}

.inputText {
  padding: 12px 16px;
  align-items: flex-start;
  color: $colorPaletteGrayscale500;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid $colorPaletteGrayscale400;

  &__code {
    @extend .inputText;
    width: 100px;
    
    &__error {
      @extend .inputText__code;
      border-color: $colorPaletteRed500;
    }
  }

  &__error {
    @extend .inputText;
    border-color: $colorPaletteRed500;
  }
}

.inputDropdown {
  height: 50px;
  padding: 12px 16px;
  align-items: flex-start;
  color: $colorPaletteGrayscale500;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid $colorPaletteGrayscale400;

  &__brand {
    @extend .inputDropdown;
    width: 190px;

    &__error {
      @extend .inputDropdown__brand;
      border-color: $colorPaletteRed500;
    }
  }

  &__error {
    @extend .inputDropdown;
    border-color: $colorPaletteRed500;
  }
}
