.add_unit_plus_icon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  filter: invert(37%) sepia(9%) saturate(3912%) hue-rotate(147deg) brightness(92%) contrast(72%);
  position: relative;
  top: -2px;
}

.link_as_button {
  cursor: pointer;
  background: none;
  border: none;
}

.unit_apt_suite_row {
  display: flex;
  gap: 16px;
  align-items: center
}

.order_unit_form_group {
  flex: 1;
}

.remove_unit_minus_icon {
  flex: 0;
  width: 18px;
  height: 18px;
  filter: invert(27%) sepia(32%) saturate(5604%) hue-rotate(15deg) brightness(97%) contrast(104%);
}
