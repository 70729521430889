.mapContainer {
  height: 500px;
  width: 100%;
}

.inspectionPin {
  cursor: pointer;

  &__bubble {
    background: white;
    width: 150px;
    padding: 10px;
    border-radius: 5px 5px 5px 0;
    border: 1px solid #b2b0b0;
    position: absolute;
    bottom: -4px;
    left: 15px;
  }

  &__icon {
    width: 35px;
    height: 35px;

    path {
      fill: 'red';
    }
  }
}
