.sharedStyles {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nameDiv {
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4B4B4B;
}

.addButton {
  border: 1px dashed #f7f7f7;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 15px;
  min-height: 40px;
  &:hover {
    background-color: #f7f7f7;
  }
}