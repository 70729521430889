@import './overrides';

// TODO: import from Haven
$colorBaseBluePrimary: #2C6F84;
$colorBaseBlueDark: #1A434F;
$colorBaseGrayLight: #CFCFCF;
$colorBaseGrayDark: #757575;
$colorBaseBlackLight: #4B4B4B;
$colorBaseBlackPrimary: #2B2B2B;
$colorBaseWhitePrimary: #ffffff;
$colorBaseWhiteDark: #F7F7F7;
$colorBaseGreenDark: #009560;
$colorBaseGreenPrimary: #00d589;
$colorBaseOrange700: #996100;
$colorBaseOrange100: #FFECCC;
$colorBaseOrange200: #FFDA99;
$colorBaseOrange700: #996100;
$colorBaseGrayscale600: #454545;
$colorBaseTeal100: #CCF2F1;
$colorBaseTeal200: #99E5E3;

.componentContainer {
  padding: 24px 0;
  position: relative;
}

.calendarPanelContainer {
  display: flex;
  flex-direction: row;
}

.calendarPanelContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthText {
  color: $colorBaseBlueDark;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Nunito Sans';
  width: 150px;
}

.dateNavigation {
  display: flex;
  margin: 10px 0;
  &__controls {
    display: flex;
    &__button {
      margin: 0 10px;
      cursor: pointer;
    }
  }
}

.timezoneText {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $colorBaseGrayDark;
  @media (min-width: 1000px) {
    position: absolute;
    top: 80px;
    left: 26px;
  }
}

.calendarContainer {
  position: relative;
  height: 600px;
  width: 100%;
}

.datesSelectedContainer {
  width: 100%;
  max-width: 20%;
  min-width: 235px;
  height: 600px;
  margin-left: 24px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid;
  border-color: $colorBaseGrayLight;
  border-radius: 4px;
  overflow-y: auto;
  text-align: left;
}

.datesSelectedContainerMobile {
  width: 90%;
  height: 100%;
  min-height: 100px;
  border: 1px solid;
  border-color: $colorBaseGrayLight;
  border-radius: 4px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  text-align: left;
}

.datesSelectedHeader {
  margin-top: 10px;
  display: flex;
  align-items: center;

  .datesSelectedHeaderText {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $colorBaseGrayDark;
  }
  .datesSelectedCopy {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    color: $colorBaseGrayDark;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      color: $colorBaseGrayLight;
    }

    .datesSelectedCopied {
      display: flex;
      color: $colorBaseGreenDark;
      margin-bottom: 2px;
      font-family: 'Nunito Sans';
      font-weight: 200;
      &:hover {
        color: $colorBaseGreenPrimary;
      }
      .datesSelectedCopiedIcon {
        margin-right: 2px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    
  }
}

.selectedHoursCountText {
  color: $colorBaseGrayDark;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 16px 0;
}

.datesSelectedDateText {
  margin-top: 10px;
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  &--eligible {
    @extend .datesSelectedDateText;
    color: $colorBaseBluePrimary
  }

  &--ineligible {
    @extend .datesSelectedDateText;
    color: $colorBaseOrange700;
  }
}

.datesSelectedTimeText {
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  height: 24px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  position: relative;

  &--eligible {
    @extend .datesSelectedTimeText;
    color: $colorBaseBluePrimary
  }

  &--ineligible {
    @extend .datesSelectedTimeText;
    color: $colorBaseOrange700;
  }

  @media (min-width: 1000px) {
    .deleteAvailabilityButtonPanel {
      display: none;
    }
  
    &:hover {
      .deleteAvailabilityButtonPanel {
        display: block;
      }
    }
  }
}

.datesSelectedWarningText {
  color: $colorBaseOrange700;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.noDatesSelected {
  margin-top: 10px;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height: 24px;
}

.deleteAvailabilityButton {
  position: absolute;
  right: -28px;
  color: $colorBaseBlackPrimary;
  bottom: -5px;
  cursor: pointer;
  z-index: 100;
  background: none;
  border: none;
}

.deleteAvailabilityButtonPanel {
  color: $colorBaseBlackPrimary;
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  right: 2px;
  bottom: 1px;
}

.rbc-calendar {
  font-family: 'Nunito Sans';
  color: $colorBaseGrayDark;
}

.rbc-event .rbc-addons-dnd-resizable {
  .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
    display: block;
    border-top: 4px double;
    width: 20px;
    margin: 5px auto;
    color: $colorBaseGrayscale600;
  }
}

.rbc-time-content {
  .rbc-day-slot {
    &.blocked {
      cursor: not-allowed;
    }
    .rbc-time-slot {
      border-top: none;
  
      &.blocked {
        background: #F7F7F7;
      }
    }
    .rbc-event {
      &.eligible {
        background: $colorBaseTeal100;
        border: 1px solid $colorBaseBluePrimary;
      }

      &.ineligible {
        background: $colorBaseOrange100;
        border: 1px solid $colorBaseOrange700;
      }

      &.rbc-selected {
        &.eligible {
          background: $colorBaseTeal200;
          border: 1px solid $colorBaseBluePrimary;
        }
  
        &.ineligible {
          background: $colorBaseOrange200;
          border: 1px solid $colorBaseOrange700;
        }
      }
    }
    .rbc-events-container {
      margin-right: 20px;

      @media (max-width: 999px) {
        margin-right: 30px;
      }
      
      .rbc-event {
        overflow: visible;
        justify-content: center;
        align-items: center;
        .rbc-event-label {
          padding-top: 0;
  
          @media (min-width: 1000px) {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
          }
        }
        .rbc-event-content {
          min-height: 0;
          height: 0;
          max-height: 0;
        }
      }
    }
  }
}

.rbc-row {
  .rbc-header {
    border-bottom: none;
    background-color: $colorBaseWhitePrimary;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &.blocked {
      background: #F7F7F7;
    }
  
    .rbc-button-link {
      padding: 10px;
      display: flex;
      align-items: center;
      border-radius: 50px;
      height: 25px;
    }
  }
}

.rbc-time-header-cell {
  height: 60px;
}

.rbc-time-header-gutter {
  width: 82px!important;
  max-width: 82px!important;
  min-width: 82px!important;
}

.rbc-time-content, .rbc-time-header-cell, .rbc-row-bg {
  .rbc-today {
    background-color: $colorBaseWhitePrimary;
  }

  .rbc-day-slot {
    border-top: 2px solid #ddd;
    @media (max-width: 999px) {
      border-right: 1px solid #ddd;
    }

    .rbc-timeslot-group .rbc-time-slot {
      border-top: none;
    }
  }
}

.rbc-time-gutter {
  .rbc-timeslot-group {
    border: none;
    .rbc-time-slot {
      width: 82px;
      position: relative;

      .rbc-label {
        position: absolute;
        right: 0;
        top: -11px;
      }
    }
  }
}

.rbc-calendar {
  .rbc-time-view {
    border: none;
    border-right: 1px solid #ddd;

    .rbc-time-content {
      border-top: none;
      overflow: visible;
    }
  }
}

.rbc-row-content {
  @media(max-width: 999px) {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

.rbc-row .rbc-today {
  .rbc-button-link {
    background-color:  $colorBaseBluePrimary;
    color: $colorBaseWhitePrimary;
  }
}

.rbc-day-slot .rbc-event-label {
  padding-top: 20%;
  text-align: center;
  font-size: 14px;;
  overflow: visible;
  white-space: normal;
  color: $colorBaseGrayscale600;
}

.rbc-day-slot:has(~ .rbc-today) {
  background: $colorBaseWhiteDark;
}

.rbc-day-bg:has(~ .rbc-today) {
  background: $colorBaseWhiteDark;
}

.rbc-header:has(~ .rbc-today) {
  background: $colorBaseWhiteDark;
}

.rbc-time-content .rbc-current-time-indicator {
  background-color: $colorBaseBluePrimary;
}

.rbc-day-bg.blocked {
  background: #F7F7F7;
  color: $colorBaseOrange700;
  padding-top: 8px;
  cursor: not-allowed;
  z-index: 100;
}

.minimumTimeslotDurationTooltip {
  background: #4B4B4B;
  padding: 4px 8px 8px 4px;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  bottom: -55px;
  white-space: normal;
  z-index: 100;

  animation: fadein 250ms;

  @media (min-width: 1000px) {
    width: calc(100% + 32px);
    white-space: normal;
    bottom: -90px;
    left: -5px;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.readOnly {
  .rbc-event {
    cursor: default;
  }
  .datesSelectedTimeText {
    .deleteAvailabilityButtonPanel {
      display: none;
    }
  }

}

.minimized {
  .datesSelectedContainer {
    margin: 0;
    height: 300px;
    min-width: 100%;
  }
}

.vertical {
  .calendarPanelContainer {
    flex-direction: column;

    .datesSelectedContainer {
      margin-left: 0;
      margin-top: 24px;
      max-width: 100%;
      height: 250px;
      padding-bottom: 20px;
    }
  }
}

.panelOnly {
  .datesSelectedContainer {
    margin-top: 0!important;
  }
}
