.contractorMarketMapContainer {
  .mapContainer {
    height: 500px;
    width: 100%;
    padding: 0 32px 0 335px;
  }
  

  .rangeValueContainer {
    display: inline-flex;
  }

  input:read-only {
    background: #e4e4e4;
  }
}
